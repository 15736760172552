<template>
  <v-alert prominent type="error" v-if="alertShow">
    <v-row align="center">
      <v-col class="grow">
        {{ alertText }}
      </v-col>
      <v-col class="shrink">
        <v-btn @click="$emit('closeAlert')">Close</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "AlertComponent",
  props: ["alertShow", "alertText"],
  data() {
    return {
      showAll: false,
    };
  },
};
</script>
